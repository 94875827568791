
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































































































$sliderBP: l;

.service-pro__hero {
  ::v-deep {
    .hero__slides__item__title {
      @include mq('l') {
        font-size: 3.5em;
      }
    }
  }
}

.service-pro__domino {
  @include mq($until: 'l') {
    margin-top: $spacing * 2;
  }
}

.service-pro__discover {
  ::v-deep {
    .shadow.product-slider::before {
      background: linear-gradient(to right, white 90%, rgba(white, 0));
    }
    .shadow.product-slider::after {
      background: linear-gradient(to left, white 90%, rgba(white, 0));
    }
  }
}

.service-pro__options {
  overflow: hidden;

  ::v-deep {
    .flickity-slider {
      @include mq($until: 'l') {
        min-height: 30rem;
      }
    }
  }

  @include mq($until: $sliderBP) {
    margin-bottom: 5rem;
  }
}

.service-pro__options__title {
  margin-bottom: 3rem;
  text-align: center;

  @include mq(m) {
    margin-bottom: 5rem;
  }
}

.service-pro__options__item {
  max-width: 36rem;
  height: 100%;

  ::v-deep .product-card__details {
    background-color: transparent;
  }

  @include mq($sliderBP) {
    height: auto;
  }
}

.service-pro__slider__items__item {
  @include mq($until: l) {
    & + & {
      margin-left: $spacing;
    }
  }

  @include mq('l') {
    max-width: 28rem;
  }

  @include mq('xl') {
    max-width: 30rem;
  }

  @include mq('xxl') {
    max-width: 36rem;
  }
}

.service-pro__anchors {
  display: flex;
  margin-top: $spacing;

  @include mq($until: m) {
    flex-direction: column;
    margin-bottom: $spacing;
  }
}

.service-pro__anchor {
  @extend %fw-bold;

  display: inline-flex;
  align-items: center;

  color: $c-blue-dark;
  line-height: 1;
  text-decoration: none;

  @include mq(m) {
    margin-bottom: $spacing * 2.5;
  }

  @include mq(l) {
    margin-top: $spacing * 2;
    margin-bottom: 0;
  }

  &:first-child {
    margin-right: $spacing * 1.5;
  }

  .icon {
    width: 3rem;
    height: 4rem;
    margin-right: $spacing;
    fill: $white;
  }

  @include mq($until: m) {
    margin-top: $spacing;
  }
}

.service-pro__banner-speedlines {
  margin-top: get-spacing(xl, s) * -1;

  @include mq(m) {
    margin-top: get-spacing(xl, m) * -1;
  }

  @include mq(xl) {
    margin-top: get-spacing(xl, l) * -1;
  }
}

.service-pro__cta {
  ::v-deep .cta__excerpt {
    max-width: 90rem;
  }
}
